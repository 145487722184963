<template>
  <header class="relative z-20 flex-col hidden h-full gap-8 px-4 py-6 text-white transition-all bg-gray-900 md:flex header">
    <nuxt-link
      to="/"
      class="flex items-center px-4 py-2"
    >
      <span class="w-6 text-white ">
        <IconResolver icon-name="deckmatch" />
      </span>
      <span
        class="grid flex-1 transition-all duration-150 grid-cols-[0fr] tracking-wider"
        :class="!isNavExpanded ? 'opacity-0' : 'ml-4 grid-cols-[1fr]'"
      >
        <span class="overflow-hidden">
          DECKMATCH
        </span>
      </span>
    </nuxt-link>
    <div class="absolute right-0 translate-x-1/2 -translate-y-1/2 top-11 ">
      <slot name="expansion-button" />
    </div>
      
    <nav class="flex flex-col justify-between flex-1 gap-y-2 ">
      <div>
        <template v-for="link in links">
          <nuxt-link
            v-if="link.to"
            :key="link.to"
            :to="link.to"
            class="flex px-4 py-2 rounded-md focus-visible:bg-gray-800 hover:bg-gray-800"
            tabindex="0"
          >
            <span class="w-6 text-gray-300">
              <IconResolver :icon-name="link.iconName" />
            </span>
            <span
              class="grid flex-1 transition-all duration-150 grid-cols-[0fr]"
              :class="!isNavExpanded ? 'opacity-0' : 'ml-4 grid-cols-[1fr]'"
            >
          
              <span class="overflow-hidden whitespace-nowrap">
                {{ link.label }}
              </span>
            </span>
          </nuxt-link>
          <button
            v-else
            :key="link.label"
            class="flex px-4 py-2 text-left rounded-md focus-visible:bg-gray-800 hover:bg-gray-800 "
            tabindex="0"
            @click="link.action"
          >
            <span class="w-6 text-gray-300">
              <IconResolver :icon-name="link.iconName" />
            </span>
            <span
              class="grid flex-1 transition-all duration-150 grid-cols-[0fr]"
              :class="!isNavExpanded ? 'opacity-0' : 'ml-4 grid-cols-[1fr]'"
            >
      
              <span class="overflow-hidden whitespace-nowrap">
                {{ link.label }}
              </span>
            </span>
          </button>
        </template>
      </div>
      <div>
        <template v-for="link in bottomLinks">
          <nuxt-link
            v-if="link.to"
            :key="link.to"
            :to="link.to"
            class="flex px-4 py-2 rounded-md focus-visible:bg-gray-800 hover:bg-gray-800"
            tabindex="0"
          >
            <span class="w-6 text-gray-300">
              <IconResolver :icon-name="link.iconName" />
            </span>
            <span
              class="grid flex-1 transition-all duration-150 grid-cols-[0fr]"
              :class="!isNavExpanded ? 'opacity-0' : 'ml-4 grid-cols-[1fr]'"
            >
          
              <span class="overflow-hidden whitespace-nowrap">
                {{ link.label }}
              </span>
            </span>
          </nuxt-link>
          <button
            v-else
            :key="link.label"
            class="flex px-4 py-2 text-left rounded-md focus-visible:bg-gray-800 hover:bg-gray-800 "
            tabindex="0"
            @click="link.action"
          >
            <span class="w-6 text-gray-300">
              <IconResolver :icon-name="link.iconName" />
            </span>
            <span
              class="grid flex-1 transition-all duration-150 grid-cols-[0fr]"
              :class="!isNavExpanded ? 'opacity-0' : 'ml-4 grid-cols-[1fr]'"
            >
      
              <span class="overflow-hidden whitespace-nowrap">
                {{ link.label }}
              </span>
            </span>
          </button>
        </template>
      </div>
    </nav>
    <div
      class="pt-4 border-t-2 border-gray-600"
    >
      <HeaderUser
        :is-nav-expanded="isNavExpanded"
        :user="data?.user"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { useSiteFunctionality } from '~/stores/siteFunctionality';
import type { IconNames } from '@/types'
type User = {
  data: {
    user: {
      name?: string;
      image?:string;
      email: string;
    }
  }
}

defineProps<{isNavExpanded: boolean}>()

const siteFunctionality = useSiteFunctionality()

const { data } = {
  user: {
      name: 'string',
      email: 'string'
    }
} as unknown as User
type Link = {
  to: string;
  label: string;
  iconName: IconNames;
  action: () => void;
}
const links = reactive<Link[]>([
//  {
//   to: '/dashboard',
//   label: 'Dashboard',
//   iconName: 'bar-chart-square',
//  },
 {
  to: '/',
  label: 'Deals',
  iconName: 'bar-chart',
  action: () => null
},
{
  to: '/rules',
  label: 'Rules',
  iconName: 'preferences',
  action: () => null
 },
{
  to: '',
  label: 'Submit Deal',
  iconName: 'upload',
  action: () => siteFunctionality.setIsUploadModalOpen(true)
 },
])
const bottomLinks = reactive<Link[]>([
//  {
//   to: '/dashboard',
//   label: 'Dashboard',
//   iconName: 'bar-chart-square',
//  },
 {
  to: '/settings',
  label: 'Settings',
  iconName: 'cog',
  action: () => null
},
])

</script>

<style scoped>

</style>