<template>
  <header class="relative flex flex-wrap justify-between px-4 py-4 text-white transition-all bg-gray-900 md:hidden header ">
    <nuxt-link
      to="/"
      class="flex items-center px-4 py-2"
    >
      <span class="w-6 text-white ">
        <IconResolver icon-name="deckmatch" />
      </span>
      <span
        class="grid flex-1 ml-4 tracking-wider "
      >
        <span class="overflow-hidden">
          DECKMATCH
        </span>
      </span>
    </nuxt-link>
    <div class="">
      <slot name="expansion-button" />
    </div>
      
    <div
      class="w-full"
    >
      <nav
        v-show="isNavExpanded"
        class="flex flex-col w-full gap-y-2"
      >
        <nuxt-link
          v-for="link in links"
          :key="link.to"
          :to="link.to"
          class="flex px-4 py-2 rounded-md focus-visible:bg-gray-800 hover:bg-gray-800"
          tabindex="0"
        >
          <span class="w-6 text-gray-300">
            <IconResolver :icon-name="link.iconName" />
          </span>
          <span
            class="grid flex-1 ml-4"
          >
        
            <span class="overflow-hidden">
              {{ link.label }}
            </span>
          </span>
        </nuxt-link>
      </nav>
      <div
        v-if="isNavExpanded"
        class="pt-4 mt-4 border-t-2 border-gray-600"
      >
        <HeaderUser
          :is-nav-expanded="isNavExpanded"
          :user="data?.user"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import type { IconNames } from '@/types'
type User = {
  data: {
    user: {
      name?: string;
      image?:string;
      email: string;
    }
  }
}
defineProps<{isNavExpanded: boolean}>()

const { data } = {
  user: {
      name: 'string',
      email: 'string'
    }
} as unknown as User
type Link = {
  to: string;
  label: string;
  iconName: IconNames;
}
const links = reactive<Link[]>([
 {
  to: '/',
  label: 'Deals',
  iconName: 'bar-chart',
 },
 {
  to: '/rules',
  label: 'Rules',
  iconName: 'preferences',
 },
 {
  to: '/settings',
  label: 'Settings',
  iconName: 'cog',
},
])

</script>

<style scoped>

</style>