<template>
  <div
    v-if="isInDeckmatchDB"
    class="relative layout"
  >
    <DashboardHeader :is-nav-expanded="isNavExpanded">
      <template #expansion-button>
        <button
          class="relative w-8 rounded-full aspect-square bg-primary-600"
          @click="isNavExpanded = !isNavExpanded"
        >
          <span
            class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            :class="isNavExpanded ? 'rotate-180' : ''"
          >
            <IconResolver icon-name="chevron_right" />
          </span>
          <span class="absolute opacity-0">
            {{ isNavExpanded ? 'Close' : 'Open' }}
          </span>
        </button>
      </template>
    </DashboardHeader>
    <DashboardHeaderMobile :is-nav-expanded="isNavExpanded">
      <template #expansion-button>
        <button
          class="relative w-8 rounded-full aspect-square bg-primary-600"
          @click="isNavExpanded = !isNavExpanded"
        >
          <span
            class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            :class="isNavExpanded ? 'rotate-180' : ''"
          >
            <IconResolver :icon-name="isNavExpanded ? 'menu-close': 'menu'" />
          </span>
          <span class="absolute opacity-0">
            {{ isNavExpanded ? 'Close' : 'Open' }}
          </span>
        </button>
      </template>
    </DashboardHeaderMobile>
    <div class="z-10 pt-4 main">
      <!-- <DashboardBreadcrumb v-if="route.fullPath !== '/dashboard'" /> -->
      <DeckUpload />

      <main class="pb-20">
        <slot />
      </main>
    </div>
  </div>
  <PageLoader v-else />
</template>

<script setup lang="ts">
import { useDeckmatchUserStore } from '~/stores/deckmatchUser';
import { useMediaQuery } from '@vueuse/core'

const isNavExpanded = ref(true)
const route = useRoute()
const deckmatchUserStore = useDeckmatchUserStore()

const isInDeckmatchDB = computed(() => !!deckmatchUserStore.getUserInfo.email)

const isLargeScreen = useMediaQuery('(min-width: 768px)')

onMounted(() => {
  if(!isLargeScreen.value) {
    isNavExpanded.value = false
  }
})

watch(
  () => route.fullPath,
  () => { isNavExpanded.value = false }
)
</script>

<style>

.layout {
  display: grid; 
  height: 100dvh;
  overflow: hidden;
  grid-template-rows: auto 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "header header"
    "main main"; 
 
  transition: grid-template-columns 1s ease-in-out;
  grid-template-columns: auto 1fr; 
  @media (min-width: 768px) {
  grid-template-areas: 
    "header main" 
    "header main";
  }
}
.breadcrumb { grid-area: breadcrumb; }
.header { grid-area: header; }
.main { grid-area: main;  overflow-y: auto; }

</style>